<template>
  <v-dialog v-model="show" persistent max-width="80%">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Listado de incendios</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col>
              <v-menu ref="fechaInit" v-model="showFechaInit" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                <template #activator="{ on, attrs }">
                  <v-text-field :value="fechaInitFormatted" @click:clear="fechaInit = null" label="Fecha desde" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined dense clearable hide-details />
                </template>
                <v-date-picker v-model="fechaInit" @input="$refs.fechaInit.save(fechaInit)" locale="es-ES" :max="fechaEnd" />
              </v-menu>
            </v-col>

            <v-col>
              <v-menu ref="fechaEnd" v-model="showFechaEnd" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                <template #activator="{ on, attrs }">
                  <v-text-field :value="fechaEndFormatted" label="Fecha hasta" @click:clear="fechaEnd = null" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined dense clearable hide-details />
                </template>
                <v-date-picker v-model="fechaEnd" @input="$refs.fechaEnd.save(fechaInit)" locale="es-ES" :min="fechaInit" />
              </v-menu>
            </v-col>
            <v-col>
              <v-text-field label="Municipio" v-model="nombreIncendioSelected" outlined dense />
            </v-col>

            <v-col>
              <v-text-field label="Localidad" v-model="localidadIncendioSelected" outlined dense />
            </v-col>

            <v-col>
              <v-select label="Estado" v-model="estadoSelected" :items="tiposIncendioEstado" item-text="INCENDIO_ESTADO" return-object clearable outlined dense />
            </v-col>

            <v-col>
              <v-select label="Tipo Detección" v-model="tipoDeteccionSelected" :items="tiposDeteccion" item-text="DETECCION" return-object clearable outlined dense />
            </v-col>

            <v-col>
              <v-select label="Nivel" v-model="nivelSelected" :items="tiposIncendioNivel" item-text="INCENDIO_NIVEL" return-object clearable outlined dense />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <span class="subtitle-1 black--text">Nº de incendios: {{ incendiosFilter.length }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table class="table" :headers="headers" :items="incendiosFilter" item-key="ID_INCENDIO" fixed-header hide-default-footer disable-pagination>
                <template #no-data>
                  <p>Datos no disponibles.</p>
                </template>

                <template #[`item.FECHA_CREACION`]="{ item }">
                  <span>{{ item.FECHA_CREACION | formatDate }}</span>
                </template>

                <!-- <template #[`item.OCULTO`]="{ item }">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" :disabled="(item.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE !== 32 && item.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE !== 50)" @click.stop.prevent="mostrarOcultarIncendio(item)">
                        <v-icon v-show="!item.OCULTO" color="black">mdi-eye-off</v-icon>
                        <v-icon v-show="item.OCULTO" color="black">mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span v-show="!item.OCULTO">Ocultar</span>
                    <span v-show="item.OCULTO">Mostrar</span>
                  </v-tooltip>
                </template> -->
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cerrar">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    show: Boolean
  },

  data: () => ({
    showFechaInit: false,
    showFechaEnd: false,
    fechaInit: null,
    fechaEnd: null,

    nombreIncendioSelected: null,
    localidadIncendioSelected: null,
    estadoSelected: null,
    tipoDeteccionSelected: null,
    nivelSelected: null,

    headers: [
      { text: 'Nombre', value: 'NOMBRE', align: 'left', sortable: true, width: '10%' },
      { text: 'Municipio', value: 'MUNICIPIO', align: 'left', sortable: true },
      { text: 'Localidad', value: 'LOCALIDAD', align: 'left', sortable: true },
      { text: 'Fecha de inicio', value: 'FECHA_CREACION', align: 'left', width: '30%', sortable: true }
      // { text: 'Ocultar/Mostrar', value: 'OCULTO', align: 'center', sortable: false }
    ],

    incendiosFilter: []
  }),

  computed: {
    ...mapGetters('incendio', [
      'tiposIncendioEstado', 'tiposDeteccion', 'tiposIncendioNivel'
    ]),

    incendios () {
      return this.$store.getters['incendio/incendiosAll']
    },

    fechaInitFormatted () {
      let date = this.$date.parseDate(this.fechaInit, 'YYYY-MM-DD')
      return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
    },
    fechaEndFormatted () {
      let date = this.$date.parseDate(this.fechaEnd, 'YYYY-MM-DD')
      return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
    }
  },

  watch: {
    show () {
      if (this.show) {
        this.incendiosFilter = this.incendios
        this.filterFechaIncendio()
      }
    },
    incendios () {
      this.filterFechaIncendio()
    },
    fechaInit () {
      this.filterFechaIncendio()
    },
    fechaEnd () {
      this.filterFechaIncendio()
    },
    nombreIncendioSelected () {
      this.filterFechaIncendio()
    },
    localidadIncendioSelected () {
      this.filterFechaIncendio()
    },
    estadoSelected () {
      this.filterFechaIncendio()
    },
    tipoDeteccionSelected () {
      this.filterFechaIncendio()
    },
    nivelSelected () {
      this.filterFechaIncendio()
    }

  },

  methods: {
    filterFechaIncendio () {
      if (this.fechaInit) {
        this.incendiosFilter = this.incendios.filter(x => x.FECHA_CREACION >= this.fechaInit)
      } else {
        this.incendiosFilter = this.incendios
      }

      if (this.fechaEnd) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.FECHA_CREACION <= this.fechaEnd)
      }

      if (this.nombreIncendioSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.MUNICIPIO.toLowerCase().includes(this.nombreIncendioSelected.toLowerCase()))
      }

      if (this.localidadIncendioSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.LOCALIDAD.toLowerCase().includes(this.localidadIncendioSelected.toLowerCase()))
      }

      if (this.estadoSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.ESTADOS[0].TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO === this.estadoSelected.INCENDIO_ESTADO)
      }

      if (this.tipoDeteccionSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.ID_TIPO_DETECCION === this.tipoDeteccionSelected.ID_TIPO_DETECCION)
      }

      if (this.nivelSelected) {
        this.incendiosFilter = this.incendiosFilter.filter(x => x.ID_TIPO_INCENDIO_NIVEL === this.nivelSelected.ID_TIPO_INCENDIO_NIVEL)
      }
    },

    mostrarOcultarIncendio (incendio) {
      let oculto = !incendio.OCULTO

      this.$store.dispatch('incendio/ocultarIncendio', {
        ID_INCENDIO: incendio.ID_INCENDIO,
        OCULTO: oculto
      })
    },

    cerrar () {
      this.$emit('cerrar')
    }
  }
}
</script>

<style scoped>
  /* Estilos para que la tabla tenga un max-height y pueda crecer */
  .table {
    max-height: 500px;
  }
  .v-data-table {
    overflow: auto;
  }
  .v-data-table >>> .v-data-table__wrapper { /* Header fijo */
    overflow: unset;
  }
</style>
